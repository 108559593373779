import React from 'react';

import Signup from '../Signup';


class SignupPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (

      <Signup />

    );
  }
}

export default SignupPage;
