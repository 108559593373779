import React from 'react';
import SchoolForm from '../SchoolForm';


class SchoolSignup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (

      <SchoolForm />

    );
  }
}

export default SchoolSignup;
